import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./store";

// Firebase
import firebase from "firebase/compat/app";
import firebaseApp from "./config";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

// Layout
import FirebaseListener from "./components/firebase/FirebaseListener";
import Navbar from "./components/layout/Navbar";
import LandingPage from "./components/layout/LandingPage";
import Footer from "./components/layout/Footer";
import OrderConfirmation from "./components/confirmation/OrderConfirmation";

// CSS
import "./static/css/public/main.css";
import "./static/css/public/vars.css";



const rrfProps = {
  firebase,
  config: firebaseApp,
  dispatch: store.dispatch,
  createFirestoreInstance,
  userProfile: "users",
  presence: "presence",
  sessions: "sessions",
};

const selectedTheme = "theme-base";

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <BrowserRouter>
          <div className={`app-wrapper ${selectedTheme}`}>
            <FirebaseListener />
            <Navbar />
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/confirmar-pedido" element={<OrderConfirmation />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
