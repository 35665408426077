import { FORM_SUBMIT, FORM_SUCCESS, FORM_FAIL } from "./actionTypes";

// Axios
import axios from "axios";

// Config
import { apiEndpoint } from "../config";

// JSON
import statesBR from "../static/json/states.json";
import citiesBR from "../static/json/cities.json";

const getStateCode = (state) => {
  const stateObj = statesBR.filter((item) => item.sigla === state);

  return stateObj[0].id;
};

const getCityCode = (state, city) => {
  const filteredCities = citiesBR.filter(
    (city) => city.microrregiao.mesorregiao.UF.sigla === state
  );

  const cityObj = filteredCities.filter((c) => c.nome === city);

  return cityObj[0].id;
};

// Submit
export const formSubmit = (formData, token) => {
  return (dispatch) => {
    // Set form to 'sending' status
    dispatch({ type: FORM_SUBMIT });

    // Format Data
    const subscriptionData = {
      campaign: formData.campaign,
      form: formData.form,
      tracker: formData.tracker,
      token: token,
      contact: {
        fullName: formData.contact.fullName,
        email: formData.contact.email,
        phoneNumber: formData.contact.phoneNumber,
      },
      location: {
        country: "Brasil",
        state: formData.address.state,
        stateCode: getStateCode(formData.address.state),
        city: formData.address.city,
        cityCode: getCityCode(formData.address.state, formData.address.city),
        neighborhood: formData.address.neighborhood,
        zipcode: formData.address.zipcode,
      },
      address: {
        country: "Brasil",
        state: formData.address.state,
        stateCode: getStateCode(formData.address.state),
        city: formData.address.city,
        cityCode: getCityCode(formData.address.state, formData.address.city),
        neighborhood: formData.address.neighborhood,
        zipcode: formData.address.zipcode,
        street: formData.address.street,
        number: formData.address.number,
        complement: formData.address.complement,
      },
      connection: {
        ipAddress: formData.connection.ipAddress,
      },
      order: {
        hasOrder: true,
        campaign: formData.campaign,
        type: formData.order.type,
        size: formData.order.size,
      },
    };

    // Post Request to API
    axios
      .post(apiEndpoint, subscriptionData)
      .then((res) => {
        if (res.data.msg === "subscribed") {
          return dispatch({
            type: FORM_SUCCESS,
            msg: "Obrigado por se inscrever. Tamo Junto!",
          });
        } else {
          return dispatch({
            type: FORM_FAIL,
            msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: FORM_FAIL,
          msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
        });
      });
  };
};
