import React from "react";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

// CSS
import "../../static/css/assets/stepbutton.css";

const StepButton = ({
  stepType,
  nextStepFunction,
  previousStepFunction,
  submitFunction,
}) => {
  switch (stepType) {
    case "first":
      return (
        <div className="step-button-wrapper">
          <div className="step-button-container">
            <div className="step-button" onClick={nextStepFunction}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <span>Próximo</span>
          </div>
        </div>
      );
    case "mid":
      return (
        <div className="step-button-wrapper">
          <div className="step-button-container">
            <div className="step-button" onClick={previousStepFunction}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <span>Anterior</span>
          </div>
          <div className="step-button-container">
            <div className="step-button" onClick={nextStepFunction}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
            <span>Próximo</span>
          </div>
        </div>
      );
    case "submit":
      return (
        <div className="step-button-wrapper">
          <div className="step-button-container">
            <div className="step-button" onClick={previousStepFunction}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <span>Anterior</span>
          </div>
          <div className="step-button-container">
            <div className="step-button" onClick={submitFunction}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </div>
            <span>Enviar</span>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default StepButton;
