// Redux
import { combineReducers } from "redux";

// Firebase
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

// Reducers
import { formReducer } from "./formReducer";
import { confirmationReducer } from "./confirmationReducer";

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: formReducer,
  confirmation: confirmationReducer,
});
