import React from "react";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCheck,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

// Config
import { formMsg } from "../../config";

// Redux
import { useSelector } from "react-redux";

const FormStepSubmit = () => {
  const formState = useSelector((state) => state.form);

  return (
    <div className="form-container">
      {formState.sendingSubscription ? (
        <div className="form-submit-box">
          <FontAwesomeIcon
            className="animate-pulse"
            icon={faPaperPlane}
            style={{
              color: "var(--theme-white-100)",
              fontSize: "30pt",
              margin: "20px",
            }}
          />
          <span className="post-form">Enviando Dados...</span>
        </div>
      ) : formState.subscribed ? (
        <div className="form-submit-box">
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              color: "var(--theme-white-100)",
              fontSize: "40pt",
              margin: "10px",
            }}
          />
          <span className="post-form">Obrigado por responder</span>
          <span className="post-form pf-large">{formMsg.success}</span>
          <span className="post-form-2">
            Um e-mail de confirmação será enviado. <br />
            <b>Caso não tenha recebido, verifique a caixa de spam.</b>
          </span>
        </div>
      ) : (
        <div className="form-submit-box">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            style={{
              color: "var(--theme-white-100)",
              fontSize: "40pt",
              margin: "10px",
            }}
          />
          <span className="post-form">Algo deu errado...</span>
          <span className="post-form">
            Por favor, tente novamente mais tarde.
          </span>
        </div>
      )}
    </div>
  );
};

export default FormStepSubmit;
