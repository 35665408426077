// Redux
import { createStore, applyMiddleware } from "redux";
import { composeFunction } from "../config";
import thunk from "redux-thunk";

// Firebase
import firebase from "firebase/compat/app";
import firebaseApp from "../config";
import { getFirestore, reduxFirestore } from "redux-firestore";
import { getFirebase } from "react-redux-firebase";

// Reducer
import rootReducer from "../reducers";

const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  composeFunction(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(firebase, firebaseApp)
  )
);

export default store;
