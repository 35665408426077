import React from "react";

// isElection
import { isElection } from "../utils/isElection";

// Config
import { candidateData } from "../../config";

// CSS
import "../../static/css/layout/footer.css";

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <div className="footer__container">
        {isElection() ? (
          <div>{`2024  |  ${candidateData.name} - Prefeito - ${candidateData.number}  |  ${candidateData.party} `}</div>
        ) : (
          <div>{`2024  |  ${candidateData.name}  |  ${candidateData.party}`}</div>
        )}
      </div>
    </div>
  );
};

export default Footer;
