// Firebase
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { getAnalytics } from "firebase/analytics";

// Redux
import { compose } from "redux";

//import { initializeApp } from "firebase/app";
//import { getFirestore } from "firebase/firestore";
//import { getAuth } from "firebase/auth";

// WebApp Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBu1rnj2yEtf4IvW7TvSy0aOe2miwIzbyA",
  authDomain: "mobilizaai.firebaseapp.com",
  projectId: "mobilizaai",
  storageBucket: "mobilizaai.appspot.com",
  messagingSenderId: "558586547606",
  appId: "1:558586547606:web:c9ee267d0870ff7aae61b7",
  measurementId: "G-Q2LQX7E6EF",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);

// General Exports
export const firebaseFirestore = firebaseApp.firestore();
export const composeFunction = compose;
export const apiEndpoint =
  "https://us-central1-mobilizaai.cloudfunctions.net/subscriptionApi/subscriptions";
export const confirmationEndpoint =
  "https://us-central1-mobilizaai.cloudfunctions.net/subscriptionApi/confirm-order";

// Candidatura
export const candidateURL = "https://tarcisiomotta.com.br/";
export const candidateData = {
  name: "Tarcísio Motta",
  position: "Prefeito",
  number: "50",
  party: "Coligação O Rio Merece Mais - Federação PSOL REDE (PSOL/REDE), PCB",
  cnpj: "56.324.773/0001-88",
};

export const candidateConfig = {
  campaign: "psol_tarcisiomotta",
  form: "NLM5HaS4IcKIWngcQxt8",
  defaultTracker: "OtSgYTof7ootXQ9LRSrl",
};

export const formMsg = {
  preCampaign: [
    "Quer receber material do Tarcísio?",
    " Preencha o formulário que a gente leva o material até você.",
  ],
  campaign: [
    "Quer receber material do Tarcísio?",
    " Preencha o formulário que a gente leva o material até você.",
  ],
  stepSize: "Diz pra gente quanto material você quer!",
  success: "Tamo Junto!",
};

export const kitCombos = [
  { type: "default", label: "Tarcísio Motta", mod: 1 },
];

export const kitConfig = [
  {
    size: "small",
    sizeName: "Pequeno",
    content: [
      { name: "panfletos", amount: 100 },
      { name: "adesivos", amount: 100 },
    ],
  },
  {
    size: "medium",
    sizeName: "Médio",
    content: [
      { name: "panfletos", amount: 200 },
      { name: "adesivos", amount: 200 },
    ],
  },
  {
    size: "large",
    sizeName: "Grande",
    content: [
      { name: "panfletos", amount: 500 },
      { name: "adesivos", amount: 500 },
    ],
  },
];

export default firebaseApp;
