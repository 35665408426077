import React, { Fragment } from "react";

// FontAwesomeIcons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

// CSS
import "../../static/css/assets/formitem.css";

const FormItem = ({
  inputType = "text",
  inputLabel = "form label",
  inputName,
  inputValue,
  inputChangeFunction = null,
  inputBlurFunction,
  selectOptions = [],
  selectPlaceholder,
  checkboxOptions = {},
  checkboxLabels = [],
  checkboxLabelAfter = true,
  submitFunction,
  inputAlert,
  csvSetStateFunction,
  csvField,
  required = false,
}) => {
  switch (inputType) {
    case "text":
      return (
        <div className="form__item">
          <span className="form__item--label">{inputLabel}</span>
          <input
            type="text"
            name={inputName}
            value={inputValue}
            onChange={inputChangeFunction}
            onBlur={inputBlurFunction}
          />
        </div>
      );
    case "select":
      return (
        <div className="form__item">
          <span className="form__item--label">{inputLabel}</span>
          <select
            name={inputName}
            value={inputValue}
            onChange={inputChangeFunction}
            onBlur={inputBlurFunction}
          >
            {selectOptions.length > 0 ? (
              selectOptions.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))
            ) : (
              <option>O array de opções é nulo</option>
            )}
          </select>
        </div>
      );
    case "selectEstadosBR":
      return selectOptions.length === 0 ? null : (
        <div className="form__item">
          <span className="form__item--label">{inputLabel}</span>
          <select
            name={inputName}
            value={inputValue}
            onChange={inputChangeFunction}
            onBlur={inputBlurFunction}
            style={
              inputValue === "" ? { color: "rgba(255, 255, 255, 0.8)" } : null
            }
          >
            <option value="" disabled hidden>
              {`${selectPlaceholder}${required ? "*" : ""}`}
            </option>
            {selectOptions.map((option) => (
              <option key={option.id} value={option.sigla}>
                {option.sigla}
              </option>
            ))}
          </select>
        </div>
      );
    case "selectCidadesBR":
      return (
        <div className="form__item">
          <span className="form__item--label">{inputLabel}</span>
          <select
            name={inputName}
            value={inputValue}
            onChange={inputChangeFunction}
            onBlur={inputBlurFunction}
            style={
              inputValue === "" ? { color: "rgba(255, 255, 255, 0.8)" } : null
            }
          >
            {selectOptions.length === 0 ? (
              <Fragment>
                <option value="" disabled hidden>
                  {`${selectPlaceholder}${required ? "*" : ""}`}
                </option>
                <option value="null" disabled>
                  escolha um estado...
                </option>
              </Fragment>
            ) : (
              <option value="" disabled hidden>
                {selectPlaceholder}
              </option>
            )}
            {selectOptions.map((option) => (
              <option key={option.id} value={option.nome}>
                {option.nome}
              </option>
            ))}
          </select>
        </div>
      );
    case "checkboxes":
      return Object.entries(checkboxOptions).length > 0 ? (
        <div className="form__item">
          <span className="form__item--label">{inputLabel}</span>
          <div className="form__item--checkboxes">
            {Object.entries(checkboxOptions).map((item, index) => (
              <div
                className="checkbox-option"
                style={{ display: "inline-flex" }}
                key={item[0]}
              >
                <label className="checkbox-container">
                  {checkboxLabelAfter ? null : checkboxLabels[index]}
                  <input
                    type="checkbox"
                    name={`${inputName}.${item[0]}`}
                    checked={item[1]}
                    onChange={inputChangeFunction}
                  />
                  {checkboxLabelAfter ? checkboxLabels[index] : null}
                  <span className="checkmark">
                    <FontAwesomeIcon
                      className="checkmark-icon"
                      icon={faCheck}
                    />{" "}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </div>
      ) : null;

    case "alertMsg":
      return inputAlert ? (
        <div className="form__item">
          <span className="form__item--alert">{inputAlert}</span>
        </div>
      ) : null;
    case "submit":
      return (
        <div className="form__item item--submit">
          <button className="form__item--submit" onClick={submitFunction}>
            <span className="form__item--submit-label">{inputLabel}</span>
          </button>
        </div>
      );

    default:
      return (
        <div className="form__item">
          <span className="form__item--label">{inputLabel}:</span>
          <input
            type="text"
            name={inputName}
            value={inputValue}
            onChange={inputChangeFunction}
          />
        </div>
      );
  }
};

export default FormItem;
