import React, { useEffect } from "react";

import { useForm, useStep } from "react-hooks-helper";

// Config
import { candidateConfig } from "../../config";

// Form Steps
import FormStepSize from "./FormStepSize";
import FormStepContactDetails from "./FormStepContactDetails";
import FormStepAddressDetails from "./FormStepAddressDetails";
import FormStepSubmit from "./FormStepSubmit";

import "../../static/css/form/form.css";

// Global Constants
const steps = [
  { id: "size" },
  { id: "details" },
  { id: "address" },
  { id: "submit" },
];

const contactState = {
  campaign: candidateConfig.campaign,
  form: candidateConfig.form,
  tracker: candidateConfig.defaultTracker,
  token: null,
  contact: {
    fullName: "",
    email: "",
    phoneNumber: "",
  },
  address: {
    state: "",
    city: "",
    neighborhood: "",
    zipcode: "",
    street: "",
    number: "",
    complement: "",
  },
  order: {
    type: "default",
    size: "",
  },
  connection: {
    ipAddress: "",
  },
};

const MultiStepForm = ({ windowObject }) => {
  const [contactData, setValue] = useForm(contactState);

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const formProps = {
    navigation,
    contactData,
    setValue,
  };

  const trackerId = new URLSearchParams(window.location.search).get("id");

  const createTargetField = (name, value) => {
    return { target: { name, value, checked: false, type: "text" } };
  };

  useEffect(() => {
    fetch("https://api.ipify.org?format=jsonp?callback=?", {
      method: "GET",
      headers: {},
    })
      .then((res) => {
        return res.text();
      })
      .then((ipAddress) => {
        return setValue(createTargetField("connection.ipAddress", ipAddress));
      })
      .catch(() => {
        return setValue(createTargetField("connection.ipAddress", null));
      });

    if (trackerId) {
      setValue(createTargetField("tracker", trackerId));
    }
  }, []);

  switch (id) {
    case "size":
      return <FormStepSize {...formProps} />;
    case "details":
      return <FormStepContactDetails {...formProps} />;
    case "address":
      return <FormStepAddressDetails {...formProps} />;
    case "submit":
      return <FormStepSubmit {...formProps} />;
    default:
      return <FormStepSize {...formProps} />;
  }
};

export default MultiStepForm;
