import React, { useState, useEffect } from "react";

// External
import cep from "cep-promise";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { formSubmit } from "../../actions/form";

// Components
import FormItem from "../assets/FormItem";
import StepButton from "../assets/StepButton";

// JSON
import statesBR from "../../static/json/states.json";
import citiesBR from "../../static/json/cities.json";
import { fbPixelTracker } from "../assets/facebookPixelTracker";

const FormStepAddressDetails = ({ contactData, setValue, navigation }) => {
  const { address } = contactData;

  const [citiesList, setCitiesList] = useState([]);
  const [currentZip, setCurrentZip] = useState();

  const dispatch = useDispatch();

  const tokens = useSelector(
    ({ firestore: { ordered } }) => ordered.tokens || null
  );

  const token = tokens ? tokens[0].token : null;

  const createTargetField = (name, value) => {
    return { target: { name, value, checked: false, type: "text" } };
  };

  // Auto populates address field based on zipcode
  const autoPopAddress = (zipcode) => {
    if (zipcode) {
      cep(zipcode)
        .then((res) => {
          let stateTarget = createTargetField("address.state", res.state);
          let cityTarget = createTargetField("address.city", res.city);
          let neighborhoodTarget = createTargetField(
            "address.neighborhood",
            res.neighborhood
          );
          let streetTarget = createTargetField("address.street", res.street);

          setValue(stateTarget);
          setValue(cityTarget);
          setValue(neighborhoodTarget);
          setValue(streetTarget);
        })
        .catch((err) => {
          let stateTarget = createTargetField("address.state", "");
          let cityTarget = createTargetField("address.city", "");
          let neighborhoodTarget = createTargetField(
            "address.neighborhood",
            ""
          );
          let streetTarget = createTargetField("address.street", "");

          setValue(stateTarget);
          setValue(cityTarget);
          setValue(neighborhoodTarget);
          setValue(streetTarget);
        });
    }
  };

  /* Sets city list based on state selection */
  useEffect(() => {
    if (address.zipcode && address.zipcode !== currentZip) {
      autoPopAddress(address.zipcode);
      setCurrentZip(address.zipcode);
    }

    if (address.state !== "") {
      setCitiesList(
        citiesBR.filter(
          (city) => city.microrregiao.mesorregiao.UF.sigla === address.state
        )
      );
    }
  }, [address.state]);

  const [alertMsg, setAlertMsg] = useState();

  const handleNextStep = () => {
    if (address.zipcode === "") {
      return setAlertMsg("Por favor, informe o CEP.");
    }
    if (address.state === "") {
      return setAlertMsg("Por favor, informe o estado.");
    }
    if (address.city === "") {
      return setAlertMsg("Por favor, informe a cidade");
    }
    if (address.neighborhood === "") {
      return setAlertMsg("Por favor, informe o bairro");
    }
    if (address.number === "") {
      return setAlertMsg("Por favor, informe o número");
    }
    setAlertMsg();
    fbPixelTracker("addressSet");
    fbPixelTracker("orderSubmitted");

    // Send Data
    dispatch(formSubmit(contactData, token));

    return navigation.next();
  };

  return (
    <div className="form-container">
      <span className="form-section-title">Só falta essa!</span>
      <span className="form-section-description">
        Conta pra gente: onde você quer receber o material?
      </span>
      <FormItem
        inputType="selectEstadosBR"
        selectOptions={statesBR}
        selectPlaceholder={"escolha um estado..."}
        inputLabel="estado"
        inputName="address.state"
        inputValue={address.state}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputType="selectCidadesBR"
        selectOptions={citiesList}
        selectPlaceholder={citiesList.length > 0 ? "escolha a cidade" : ""}
        inputLabel="cidade"
        inputName="address.city"
        inputValue={address.city}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputLabel="bairro"
        inputName="address.neighborhood"
        inputValue={address.neighborhood}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputLabel="endereço"
        inputName="address.street"
        inputValue={address.street}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputLabel="número"
        inputName="address.number"
        inputValue={address.number}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputLabel="complemento"
        inputName="address.complement"
        inputValue={address.complement}
        inputChangeFunction={setValue}
      />
      {alertMsg ? <span className="form-alert">{alertMsg}</span> : null}
      <StepButton
        stepType="mid"
        previousStepFunction={navigation.previous}
        nextStepFunction={handleNextStep}
      />
    </div>
  );
};

export default FormStepAddressDetails;
