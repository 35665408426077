import {
  CONFIRMATION_SUBMIT,
  CONFIRMATION_SUCCESS,
  CONFIRMATION_FAIL,
} from "../actions/actionTypes";

const initialState = {
  sending: false,
  sent: false,
  success: false,
  msg: null,
};

export const confirmationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRMATION_SUBMIT:
      return {
        ...state,
        sending: true,
      };
    case CONFIRMATION_SUCCESS:
      return {
        ...state,
        sending: false,
        sent: true,
        success: true,
        msg: action.msg,
      };
    case CONFIRMATION_FAIL:
      return {
        ...state,
        sending: false,
        sent: true,
        success: false,
        msg: action.msg,
      };
    default:
      return state;
  }
};
