import React, { useState } from "react";
import { fbPixelTracker } from "../assets/facebookPixelTracker";

// Components
import FormItem from "../assets/FormItem";
import StepButton from "../assets/StepButton";

const FormStepContactDetails = ({ contactData, setValue, navigation }) => {
  const { order, contact, address } = contactData;
  const { type, size } = order;

  const [alertMsg, setAlertMsg] = useState();

  const handleNextStep = () => {
    if (contact.fullName === "") {
      return setAlertMsg("Por favor, preencha seu nome.");
    }
    if (contact.email === "") {
      return setAlertMsg("Por favor, preencha seu e-mail.");
    }
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        contact.email
      ) === false
    ) {
      return setAlertMsg(`Por favor, insira um e-mail válido`);
    }
    if (contact.phoneNumber === "") {
      return setAlertMsg("Por favor, preencha seu telefone.");
    }
    if (address.zipcode === "") {
      return setAlertMsg("Por favor, preencha seu CEP");
    }
    setAlertMsg();
    fbPixelTracker("contactInfoSet");

    return navigation.next();
  };

  const kitSizeParser = (kit) => {
    switch (kit) {
      case "small":
        return "pequeno";
      case "medium":
        return "médio";
      case "large":
        return "grande";
      default:
        return "pequeno";
    }
  };

  return (
    <div className="form-container">
      <span className="form-section-title">
        Kit {kitSizeParser(size)}? É pra já!
      </span>
      <span className="form-section-description">
        Preenche os seus dados para você ficar por dentro do que tá rolando e a
        gente poder fazer a entrega.
      </span>
      <FormItem
        inputLabel="nome completo"
        inputName="contact.fullName"
        inputValue={contact.fullName}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputLabel="e-mail"
        inputName="contact.email"
        inputValue={contact.email}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputLabel="telefone (whatsapp)"
        inputName="contact.phoneNumber"
        inputValue={contact.phoneNumber}
        inputChangeFunction={setValue}
      />
      <FormItem
        inputLabel="cep"
        inputName="address.zipcode"
        inputValue={address.zipcode}
        inputChangeFunction={setValue}
      />

      {alertMsg ? <span className="form-alert">{alertMsg}</span> : null}
      <StepButton
        stepType="mid"
        previousStepFunction={navigation.previous}
        nextStepFunction={handleNextStep}
      />
    </div>
  );
};

export default FormStepContactDetails;
