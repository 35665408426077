import React, { useEffect } from "react";

// Config
import { candidateData, formMsg } from "../../config";

// Components
import MultiStepForm from "../form/MultiStepForm";

// Functions
import { isElection } from "../utils/isElection";

// Imagem
import CandidateImg from "../../config/img/candidato.png";
import CandidateImgPre from "../../config/img/candidato-pre.png";
import CandidateLogoImg from "../../config/img/logo.png";
import CandidateLogoImgPre from "../../config/img/logo-pre.png";

// CSS
import "../../static/css/layout/landing.css";
import { fbPixelTracker } from "../assets/facebookPixelTracker";

const LandingPage = () => {
  useEffect(() => {
    fbPixelTracker("pageLoadKit");
  });

  return (
    <div className="landing__wrapper">
      <div className="landing__container">
        <div className="landing__header">
          <div className="landing__header--picture">
            {isElection() ? (
              <img
                src={CandidateImg}
                alt={candidateData.name}
                title="Preenche aqui seus dados!"
              />
            ) : (
              <img
                src={CandidateImgPre}
                alt={candidateData.name}
                title="Preenche aqui seus dados!"
              />
            )}
          </div>
          <div className="landing__header--description">
            <div className="description--title">
              {isElection() ? (
                <img
                  src={CandidateLogoImg}
                  alt={
                    isElection()
                      ? `${candidateData.name} - ${candidateData.number}`
                      : `${candidateData.name}`
                  }
                  title={
                    isElection()
                      ? `${candidateData.name} - ${candidateData.position} - ${candidateData.number}`
                      : `${candidateData.name} - ${candidateData.position}`
                  }
                  style={{ margin: "20px", width: "calc(100% - 40px)" }}
                />
              ) : (
                <img
                  src={CandidateLogoImgPre}
                  alt={
                    isElection()
                      ? `${candidateData.name} - ${candidateData.number}`
                      : `${candidateData.name}`
                  }
                  title={
                    isElection()
                      ? `${candidateData.name} - ${candidateData.position} - ${candidateData.number}`
                      : `${candidateData.name} - ${candidateData.position}`
                  }
                  style={{ margin: "20px", width: "calc(100% - 40px)" }}
                />
              )}
            </div>
            <div className="description--content">
              <p>
                <b>{isElection() ? formMsg.campaign : formMsg.preCampaign}</b>
              </p>
            </div>
          </div>
        </div>

        <div className="landing__form">
          <MultiStepForm />
        </div>
        <span className="legal-consent-text">
          Ao preencher o formulário, você concorda em receber mensagens
          relacionadas a questões políticas e sociais, mandato eletivo e
          campanha eleitoral de {candidateData.name}.
        </span>
      </div>
    </div>
  );
};

export default LandingPage;
