import React, { useState, useEffect } from "react";

// Firebase
import { getFirestore, doc, getDoc } from "firebase/firestore";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { orderConfirmation } from "../../actions/confirmation";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faCheck,
  faExclamationTriangle,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

// CSS
import "../../static/css/layout/landing.css";

const OrderConfirmation = () => {
  const confirmationId = new URLSearchParams(window.location.search).get("id");

  const confirmation = useSelector((state) => state.confirmation || {});

  const dispatch = useDispatch();

  const [loadingId, setLoadingId] = useState(true);
  const [orderExists, setOrderExists] = useState();
  const [orderConfirmed, setOrderConfirmed] = useState();
  const [orderId, setOrderId] = useState();
  const [sToken, setSToken] = useState();

  useEffect(() => {
    if (confirmationId && loadingId) {
      setOrderId(confirmationId);
      setLoadingId(false);

      const firestore = getFirestore();
      const docRef = doc(firestore, "tracking", confirmationId);

      getDoc(docRef)
        .then((snap) => {
          if (snap.exists()) {
            const trackingData = snap.data();

            if (!trackingData.confirmed) {
              setOrderExists(true);
              dispatch(orderConfirmation(confirmationId));
            } else {
              setOrderExists(true);
              setOrderConfirmed(true);
            }
          } else {
            setOrderExists(false);
            return;
          }
        })
        .catch((err) => {
          setOrderExists(false);
          return;
        });
    }
  }, []);

  return (
    <div className="landing__wrapper">
      <div className="landing__container">
        <div className="landing__confirmation-box">
          {confirmation.sending ? (
            // Enviando Confirmação
            <div className="form-submit-box">
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{
                  color: "var(--offwhite)",
                  fontSize: "40pt",
                  margin: "10px",
                }}
              />
              <span className="post-form pf-large">Pedido Encontrado!</span>
              <span className="post-form">
                Enviando a confirmação. Agora falta pouco!
              </span>
            </div>
          ) : confirmation.sent ? (
            confirmation.success ? (
              // Pedido Confirmado
              <div className="form-submit-box">
                <FontAwesomeIcon
                  icon={faCheck}
                  style={{
                    color: "var(--offwhite)",
                    fontSize: "40pt",
                    margin: "10px",
                  }}
                />
                <span className="post-form pf-large">Pedido Confirmado!</span>
                <span className="post-form">Agora é só aguardar</span>

                <span className="post-form-2">
                  O pedido será entregue em seu endereço nos próximos dias.
                </span>
              </div>
            ) : (
              // Não foi possível confirmar o pedido
              <div className="form-submit-box">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={{
                    color: "var(--offwhite)",
                    fontSize: "40pt",
                    margin: "10px",
                  }}
                />
                <span className="post-form pf-large">Algo deu Errado</span>
                <span className="post-form">
                  Por favor, tente novamente mais tarde.
                </span>
              </div>
            )
          ) : loadingId ? (
            // Carregando...
            <div className="form-submit-box">
              <span className="post-form pf-large">Buscando Pedido...</span>
              <span className="post-form">Aguarde um momento</span>
            </div>
          ) : orderId && orderExists ? (
            orderConfirmed ? (
              // Pedido Existe, tem ID na URL, mas já está confirmado
              <div className="form-submit-box">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{
                    color: "var(--offwhite)",
                    fontSize: "40pt",
                    margin: "10px",
                  }}
                />
                <span className="post-form pf-large">
                  Esse pedido já está confirmado
                </span>
                <span className="post-form-2">
                  Verificamos em nossa base que a confirmação para esse pedido
                  já foi feita. <br />
                  Agora basta aguardar a entrega.
                </span>
              </div>
            ) : (
              // Pedido Existe, tem ID na URL e não está confirmado
              <div className="form-submit-box">
                <span className="post-form pf-large">Buscando Pedido...</span>
                <span className="post-form">Aguarde um momento</span>
              </div>
            )
          ) : orderExists === false ? (
            // Pedido Não Existe ou Não Tem ID na URL
            <div className="form-submit-box">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{
                  color: "var(--offwhite)",
                  fontSize: "40pt",
                  margin: "10px",
                }}
              />
              <span className="post-form pf-large">Pedido não Encontrado</span>
              <span className="post-form">
                Não conseguimos encontrar este pedido na nossa base
              </span>
              <span className="post-form-2">
                Se estiver com problemas para confirmar seu pedido, entre em
                contato com a campanha através das redes sociais.
              </span>
            </div>
          ) : (
            // orderExists === undefined -> Carregando...
            <div className="form-submit-box">
              <span className="post-form pf-large">Buscando Pedido...</span>
              <span className="post-form">Aguarde um momento</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
