import {
  CONFIRMATION_SUBMIT,
  CONFIRMATION_SUCCESS,
  CONFIRMATION_FAIL,
} from "./actionTypes";

// Axios
import axios from "axios";

// Config
import { confirmationEndpoint, candidateConfig } from "../config";

// Confirmation
export const orderConfirmation = (orderId) => {
  return (dispatch) => {
    // Set form to 'sending' status
    dispatch({ type: CONFIRMATION_SUBMIT });

    // Confirmation Data
    const confirmationData = {
      id: orderId,
      campaign: candidateConfig.campaign,
      token: "8fdf63b8-4a8c-44e2-93e0-7f3b018416cb",
    };

    // Post Request to API
    axios
      .post(confirmationEndpoint, confirmationData)
      .then((res) => {
        if (res.data.type === "success") {
          return dispatch({
            type: CONFIRMATION_SUCCESS,
            msg: "Pedido Confirmado",
          });
        } else {
          return dispatch({
            type: CONFIRMATION_FAIL,
            msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
          });
        }
      })
      .catch((err) => {
        return dispatch({
          type: CONFIRMATION_FAIL,
          msg: "Algo deu errado. Por favor, tente novamente mais tarde.",
        });
      });
  };
};
