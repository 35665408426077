import React from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

// Config
import { candidateURL } from "../../config";

// CSS
import "../../static/css/layout/navbar.css";

const Navbar = () => {
  return (
    <div className="navbar__wrapper">
      <div className="navbar__container">
        <a
          href={candidateURL}
          rel="noopener noreferrer"
          title="Voltar para o Site"
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ marginRight: "8px" }}
          />
          <span>VOLTAR PARA O SITE</span>
        </a>
      </div>
    </div>
  );
};

export default Navbar;
