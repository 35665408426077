import React from "react";

// Config
import { kitConfig } from "../../config";

const FormStepSizeSelector = ({
  selectedSize,
  handleSizeSelection,
  sizeModifier,
}) => {
  const handleSelection = (size) => {
    handleSizeSelection(size);
  };

  const modifier = sizeModifier || 1;

  return (
    <div className="form-kitselection-container">
      <div className="form-kittype-title">Qual o Tamanho do Kit?</div>
      <div className="form-kitsize-container">
        {kitConfig.map((size) => (
          <div
            className={`${
              selectedSize === size.size ? "kit-selected" : ""
            } form-kitsize-button`}
            onClick={() => handleSelection(size.size)}
            key={size.size}
          >
            <span className="form-kitsize-title">Kit {size.sizeName}</span>
            <ul>
              {size.content.map((item) => (
                <li className="form-kitsize-desc" key={item.name}>
                  <b>{Math.ceil(item.amount * modifier)}</b> {item.name}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormStepSizeSelector;
