import React, { useState } from "react";

// Config
import { formMsg, kitCombos } from "../../config";
import { fbPixelTracker } from "../assets/facebookPixelTracker";

// Assets
import FormItem from "../assets/FormItem";

// Components
import StepButton from "../assets/StepButton";
import FormStepSizeSelector from "./FormStepSizeSelector";

const FormStepContactDetails = ({ contactData, setValue, navigation }) => {
  const { order } = contactData;
  const { type, size } = order;

  const [alertMsg, setAlertMsg] = useState();

  const createTargetField = (name, value) => {
    return { target: { name, value, checked: false, type: "text" } };
  };

  const handleSizeSelection = (sizeSelected) => {
    let sizeTarget = createTargetField("order.size", sizeSelected);
    setValue(sizeTarget);
  };

  const handleNextStep = () => {
    if (size === "") {
      return setAlertMsg("Por favor, escolha um tamanho de kit");
    }
    if (type === "") {
      return setAlertMsg("Por favor, escolha um tipo de kit");
    }

    fbPixelTracker("kitSelected");

    setAlertMsg();
    return navigation.next();
  };

  const selectedType =
    kitCombos.filter((item) => item.label === order.type)[0] || kitCombos[0];

  return (
    <div className="form-container flex-ccc">
      <span className="form-section-title">Quanto material?</span>
      <span className="form-section-description">{formMsg.stepSize}</span>
      <FormStepSizeSelector
        selectedSize={size}
        handleSizeSelection={handleSizeSelection}
        sizeModifier={selectedType.mod}
      />
      <span className="form-kitselection-container" style={{ "visibility": "hidden", "display": "none" }} >
        <div className="form-kittype-title">Que tipo de Kit você quer?</div>
        <FormItem
          inputType="select"
          selectOptions={kitCombos}
          inputLabel=""
          inputName="order.type"
          inputChangeFunction={setValue}
        />
      </span>
      {alertMsg ? <span className="form-alert">{alertMsg}</span> : null}
      <StepButton stepType="first" nextStepFunction={handleNextStep} />
    </div >
  );
};

export default FormStepContactDetails;
