// Redux
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Firebase
import { useFirestoreConnect } from "react-redux-firebase";

const FirebaseListener = () => {
  const tokens = useSelector(
    ({ firestore: { ordered } }) => ordered.tokens || null
  );

  const [listener, setListener] = useState([]);

  useEffect(() => {
    if (tokens === null) {
      setListener([
        {
          collection: "tokens",
          orderBy: ["timestamp", "desc"],
          limit: 1,
          storeAs: "tokens",
        },
      ]);
    }
  }, []);

  useFirestoreConnect(listener);
};

export default FirebaseListener;
